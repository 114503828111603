import React, { useEffect } from "react";
import { useAppContext } from "../../lib/contextLib";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

export function Logout() {
  const { isAuthenticated, userHasAuthenticated, session, setSession } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(`Pre-logout session: ${JSON.stringify(session)}`);
    console.log(`Pre-logout IsAuthenticated: ${isAuthenticated}`);

    Auth.signOut();
    setSession("");
    userHasAuthenticated(false);

    console.log(`Session: ${JSON.stringify(session)}`);
    console.log(`IsAuthenticated: ${isAuthenticated}`);

    navigate("/");
  })

  return(
    <div className="Logout">
      <h1>Logging you out...</h1>
    </div>
  )
}