import React, { useState } from "react";
import { AppContext } from "./lib/contextLib";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav"
import "./App.css";

import { Routes, Route } from "react-router-dom";
import Home from "./containers/Home/Home";
import { Login } from "./containers/Login/Login";
import { Logout } from "./containers/Logout/Logout";
import NotFound from "./containers/NotFound/NotFound";
import { Link } from "react-router-dom";
// import AppRoutes from './AppRoutes';

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userChallengeCode, setUserChallengeCode] = useState("");
  const [session, setSession] = useState("");

  console.log(session);

  return (
    <div className="App container py-3">
      <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
        <Link to="/" className="font-weight-bold text-muted">
          creddist
        </Link>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            {isAuthenticated
              ? <Link to="/logout">Logout</Link>
              : <Link to="/login">Login</Link>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <AppContext.Provider value={{ 
        isAuthenticated: isAuthenticated, 
        userHasAuthenticated: userHasAuthenticated,
        userEmail: userEmail,
        setUserEmail: setUserEmail,
        userChallengeCode: userChallengeCode,
        setUserChallengeCode: setUserChallengeCode,
        session: session,
        setSession: setSession
      }}>
        {/* <AppRoutes /> */}
        <Routes>
          <Route exact path="/" element={ <Home /> } />
          <Route exact path="/login" element={ <Login /> } />
          <Route exact path="/logout" element={ <Logout /> } />
          <Route path="*" element={ <NotFound /> } />
        </Routes>
      </AppContext.Provider>
    </div>
  );
}

export default App;