const config = {
  // Backend config
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: process.env.REACT_APP_BUCKET,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_API_URL,
  },
  cognito: {
    REGION: "us-west-1",
    USER_POOL_ID: "us-west-1_xxBR1cNwo",
    APP_CLIENT_ID: "6secq1kcltjlvqvguuf86n0mqr"
  },
};

export default config;