import React, { useState } from "react";
import { useAppContext } from "../../lib/contextLib";
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import "./Login.css";
import { Auth } from "aws-amplify";

export function Login() {
  const { userHasAuthenticated, session, setSession } = useAppContext();
  const [ authStep, setAuthStep ] = useState("email");
  const [ userEmail, setUserEmail ] = useState("");
  const [ userChallengeCode, setUserChallengeCode ] = useState("");
  const navigate = useNavigate();

  function validateEmail() {
    return userEmail.length > 0;
  }

  function validateCode() {
    return userChallengeCode.length === 6;
  }

  async function handleEmailSubmit(event) {
    event.preventDefault();

    try {
      let userInfo = await Auth.signIn(userEmail);
      setSession(userInfo);
      console.log(userInfo);
      setAuthStep("otp");
    } catch (e) {
      alert(e.message);
    }
  }

  async function handleCodeSubmit(event) {
    event.preventDefault();

    try {
      let codeInfo = await Auth.sendCustomChallengeAnswer(session, userChallengeCode);
      console.log(codeInfo);
      setSession(codeInfo);
      userHasAuthenticated(true);
      navigate("/");
    } catch (e) {
      alert(e.message);
    }
  }

  if(authStep === "email") {
    return (
      <div className="Login">
        <Form onSubmit={handleEmailSubmit}>
          <Form.Group size="lg" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </Form.Group>
  
          <Button block size="lg" type="submit" disabled={!validateEmail()}>
            Login
          </Button>
        </Form>
      </div>
    )   
  }
  else if(authStep === "otp") {
    return (
      <div className="Login">
        <Form onSubmit={handleCodeSubmit}>
          <Form.Group size="lg" controlId="email">
            <Form.Label>OTP</Form.Label>
            <Form.Control
              autoFocus
              type="number"
              value={userChallengeCode}
              onChange={(e) => setUserChallengeCode(e.target.value)}
            />
          </Form.Group>

          <Button block size="lg" type="submit" disabled={!validateCode()}>
            Login
          </Button>
        </Form>
      </div>
    )
  }
}