import React from 'react';
import { useAppContext } from "../../lib/contextLib";
import './Home.css';

export default function Home() {
  const { isAuthenticated, session } = useAppContext();
  return(
    <div className="Home">
      <div className="lander">
        <h1>creddist</h1>
        <p className="text-muted">A simple credential distribution app.</p>
        { isAuthenticated 
          ? <div className="userInfo">
              <p className="text-muted">Hello, <strong>{session.attributes.name}</strong>.</p>
              <p className="text-muted">Your email address is <strong>{session.attributes.email}</strong>.</p>
              <p className="text-muted">Your Cognito user ID is <strong>{session.attributes.sub}</strong>.</p>
            </div>
          : <p className="text-muted userInfo">Log in to see info here.</p>
        }
      </div>
    </div>
  )
}